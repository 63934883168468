<template>
    <div class="kt-quick-panel--right kt-demo-panel--right kt-offcanvas-panel--right kt-header--fixed kt-header-mobile--fixed kt-subheader--fixed kt-subheader--enabled kt-subheader--solid kt-aside--enabled kt-aside--fixed">

        <!-- begin:: Page -->
        <div class="kt-grid kt-grid--ver kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid  kt-error-v1"
                 v-bind:style="{backgroundImage: `url(/images/error-bg.jpg)`}">
                <div class="kt-error-v1__container">
                    <h1 class="kt-error-v1__number">404</h1>
                    <p class="kt-error-v1__desc">
                        OOPS! Something went wrong here
                    </p>
                    <p class="kt-error-v1__desc">
                        <router-link :to="{ name: 'base'}" class="btn btn-primary">
                            Go to home page
                        </router-link>
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "page-not-found"
    }
</script>

<style scoped>
    @import "../assets/style/error.min.css";
</style>